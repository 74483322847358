import { render, staticRenderFns } from "./BannerStatistics.vue?vue&type=template&id=6ce559c8&scoped=true&"
import script from "./BannerStatistics.vue?vue&type=script&lang=js&"
export * from "./BannerStatistics.vue?vue&type=script&lang=js&"
import style0 from "./BannerStatistics.vue?vue&type=style&index=0&id=6ce559c8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce559c8",
  null
  
)

export default component.exports
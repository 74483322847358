<template>
<div>
    <div>
      <h1 class="heading">Banner statistics</h1>
      <ObjectFilter
          :object_id="$store.state.statistics.object_id"
          :objects="objects.list"
          @changeObject="changeObject"
          @changeDates="changeDates"
          @reloadData="reloadData"
      />
    </div>
    <v-tabs>
      <v-tab>
        Intro
      </v-tab>
      <v-tab>
        Modal
      </v-tab>
      <v-tab>
        Bottom
      </v-tab>
      <v-tab-item>
        <div class="statistics">
        <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
          <v-autocomplete
              :style="`width:${isMobile?'150px':'320px'};`"
              class="statistics__type"
              solo
              flat
              dense
              outlined
              :placeholder="$t('statistics[\'Открытия\']')"
              :items="
                Object.keys(introChart).map((key) => ({ status: key, name: key }))
              "
              item-value="status"
              item-text="name"
              v-model="current_banner_intro"
              @change="changeIntroStatus"
          >
            <template v-slot:selection="{ item }">
              {{ checkIntroName(item.status) }}
            </template>
            <template v-slot:item="{ item }">
              {{ checkIntroName(item.status) }}
            </template>
          </v-autocomplete>
          <div class="statistics__chart">
            <Chart
                  style="height:415px"
                  :height="415"
                :data="introChartData"
                :labels="dateArr"
                :pointRadius="4"
                :xAxes_ticks="true"
            />
          </div>
        </div>
        <div class="statistics__info" v-if="!isMobile">
          <h3 class="statictics__caption">
            Number of shows
          </h3>
          <p class="statistics__period">
            <span>{{ totalIntro }}</span>
            {{ $t('statistics["за текущий период"]') }}
          </p>
          <p class="statistics__total">
              <span
              >The graph shows the number of intro show in your mobile
                application: {{ hotelName }}</span
              >
          </p>
        </div>
        </div>
        <div style="margin-bottom: 15px">
          <v-data-table
              class="table"
              :headers="introStatisticsTableHeaders"
              :items="introTableItems"
              hide-default-footer
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="items.length">
              <tr v-for="(item, i) in items" :key="i">
                <td>
                  {{item.date}}
                </td>
                <td>
                  {{item.value}}
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td
                    class="d-block d-sm-table-cell"
                    colspan="3"
                    style="
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
                >
                  Sorry no data for you
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="statistics">
          <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
            <v-autocomplete
                :style="`width:${isMobile?'150px':'320px'};`"
                class="statistics__type"
                solo
                flat
                dense
                outlined
                :placeholder="$t('statistics[\'Открытия\']')"
                :items="
                events
              "
                item-value="status"
                item-text="name"
                v-model="current_event_modal"
                @change="changeEventModal"
            >
              <template v-slot:selection="{ item }">
                {{ item }}
              </template>
              <template v-slot:item="{ item }">
                {{ item }}
              </template>
            </v-autocomplete>
            <v-autocomplete
                :style="`width:${isMobile?'150px':'320px'};left:${isMobile?'250px':'400px'};`"
                class="statistics__type"
                solo
                flat
                dense
                outlined
                :placeholder="$t('statistics[\'Открытия\']')"
                :items="
                Object.keys(modalChart[eventStatuses[current_event_modal]]).map((key) => ({ status: key, name: key }))
              "
                item-value="status"
                item-text="name"
                v-model="current_banner_modal"
                @change="changeModalBanner"
            >
              <template v-slot:selection="{ item }">
                {{ checkModalName(item.status) }}
              </template>
              <template v-slot:item="{ item }">
                {{ checkModalName(item.status) }}
              </template>
            </v-autocomplete>
            <div class="statistics__chart">
              <Chart
                  style="height:415px"
                  :height="415"
                  :data="modalChart[eventStatuses[current_event_modal]][current_banner_modal]"
                  :labels="dateArr"
                  :pointRadius="4"
                  :xAxes_ticks="true"
              />
            </div>
          </div>
          <div class="statistics__info" v-if="!isMobile">
            <h3 class="statictics__caption">
              Number of shows
            </h3>
            <p class="statistics__period">
              <span>{{ totalModal }}</span>
              {{ $t('statistics["за текущий период"]') }}
            </p>
            <p class="statistics__total">
              <span
              >The graph shows the number of modal banner {{ current_event_modal.toLowerCase() }} in your mobile
                application: {{ hotelName }}</span
              >
            </p>
          </div>
        </div>

        <div style="margin-bottom: 15px">
          <v-data-table
              class="table"
              :headers="introStatisticsTableHeaders"
              :items="modalTableItems"
              hide-default-footer
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="items.length">
              <tr v-for="(item, i) in items" :key="i">
                <td>
                  {{item.date}}
                </td>
                <td>
                  {{item.value}}
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td
                    class="d-block d-sm-table-cell"
                    colspan="3"
                    style="
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
                >
                  Sorry no data for you
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
      <v-tab-item>

        <div class="statistics">
          <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
            <v-autocomplete
                :style="`width:${isMobile?'150px':'320px'};`"
                class="statistics__type"
                solo
                flat
                dense
                outlined
                :placeholder="$t('statistics[\'Открытия\']')"
                :items="
                events
              "
                item-value="status"
                item-text="name"
                v-model="current_event_bottom"
                @change="changeEventBottom"
            >
              <template v-slot:selection="{ item }">
                {{ item }}
              </template>
              <template v-slot:item="{ item }">
                {{ item }}
              </template>
            </v-autocomplete>
            <v-autocomplete
                :style="`width:${isMobile?'150px':'320px'};left:${isMobile?'250px':'400px'};`"
                class="statistics__type"
                solo
                flat
                dense
                outlined
                :placeholder="$t('statistics[\'Открытия\']')"
                :items="
               Object.keys(bottomChart[eventStatuses[current_event_bottom]]).map((key) => ({ status: key, name: key }))
              "
                item-value="status"
                item-text="name"
                v-model="current_banner_bottom"
                @change="changeBottomBanner"
            >
              <template v-slot:selection="{ item }">
                {{ checkBottomName(item.status) }}
              </template>
              <template v-slot:item="{ item }">
                {{ checkBottomName(item.status) }}
              </template>
            </v-autocomplete>
            <div class="statistics__chart">
              <Chart
                  style="height:415px"
                  :height="415"
                  :data="bottomChart[eventStatuses[current_event_bottom]][current_banner_bottom]"
                  :labels="dateArr"
                  :pointRadius="4"
                  :xAxes_ticks="true"
              />
            </div>
          </div>
          <div class="statistics__info" v-if="!isMobile">
            <h3 class="statictics__caption">
              Number of shows
            </h3>
            <p class="statistics__period">
              <span>{{ totalBottom }}</span>
              {{ $t('statistics["за текущий период"]') }}
            </p>
            <p class="statistics__total">
              <span
              >The graph shows the number of bottom banner {{ current_event_bottom.toLowerCase() }} in your mobile
                application: {{ hotelName }}</span
              >
            </p>
          </div>
        </div>

        <div style="margin-bottom: 15px">
          <v-data-table
              class="table"
              :headers="introStatisticsTableHeaders"
              :items="bottomTableItems"
              hide-default-footer
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="items.length">
              <tr v-for="(item, i) in items" :key="i">
                <td>
                  {{item.date}}
                </td>
                <td>
                  {{item.value}}
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td
                    class="d-block d-sm-table-cell"
                    colspan="3"
                    style="
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
                >
                  Sorry no data for you
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>

      </v-tab-item>
    </v-tabs>
</div>
</template>

<script>
import titleMixin from "@/mixins/titleMixin";
import store from "@/store";
import {mapState} from "vuex";
import StatisticsService from "@/services/statistics.service";
export default {
  name: "BannerStatistics",
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  data(){
    return{
      isMobile:false,
      hotelName:"",
      chart_data:[],
      dateArr:[],
      clearDates:[],

      introChart:[],
      modalChart: {
        show:{
          All:[]
        },
        click_link:{
          All:[]
        },
        click_video_link:{
          All:[]
        }
      },
      bottomChart:{
        show:{
          All:[]
        },
        click_link:{
          All:[]
        },
        click_video_link:{
          All:[]
        }
      },
      events:['Show','Click link','Click video link'],
      eventStatuses:{
        Show:'show',
        'Click link':'click_link',
        'Click video link':'click_video_link'
      },

      current_event_modal:"Show",
      current_banner_modal:"All",
      modalBanners:[],

      current_event_bottom:"Show",
      current_banner_bottom:"All",
      bottomBanners:[],

      current_banner_intro:"All",
      introBanners:[],
      introStatisticsTableHeaders: [
          { text: "Date", value: "date" },
          { text: "Opens", value: "value" },
        ],
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.objects.list.length) {
      store
          .dispatch("objects/getList")
          .then((data) => {
            next();
          })
          .catch(() => {
            next();
          });
    } else {
      next();
    }
  },
  components:{
    ObjectFilter: () => import("@/components/ObjectFilter.vue"),
    Chart: () => import("@/components/Chart.vue"),
  },
  methods:{
    changeEventBottom(event){
      this.current_banner_bottom = "All";
    },
    changeBottomBanner(banner){
      //
    },
    changeEventModal(event){
      this.current_banner_modal = "All";
    },
    changeModalBanner(banner){
      //
    },
    formatDateForTable(date) {
      if (date.includes("-")) {
        const dateArr = date.split("-");
        return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`;
      } else {
        return date;
      }
    },
    checkModalName(id){
      if(id !== 'All'){
        const candidate = this.modalBanners.find(x => +x.banner_id === +id);
        if(candidate){
          return candidate.name['en'];
        }
      }else{
        return 'All';
      }
    },
    checkIntroName(id){
      if(id !== 'All'){
        const candidate = this.introBanners.find(x => +x.banner_id === +id);
        if(candidate){
          return candidate.name['en'];
        }
      }else{
        return 'All';
      }
    },
    checkBottomName(id){
      if(id !== 'All'){
        const candidate = this.bottomBanners.find(x => +x.banner_id === +id);
        if(candidate){
          return candidate.name['en'];
        }
      }else{
        return 'All';
      }
    },
    changeIntroStatus(status){
      //
    },
    formatDateForStatistics(date) {
      if(!date){
        return;
      }
      const dateArr = date.split(".");
      return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
    },
    formatDateForChart(date) {
      if (date.includes("-")) {
        const dateArr = date.split("-");
        return `${dateArr[2]}.${dateArr[1]}`;
      } else {
        return date;
      }
    },
    async reloadData() {
      await this.getBannersStatistics();
    },
    changeObject(id) {
      this.$store.state.statistics.object_id = id;
      if (
          !this.$store.state.statistics.date_start &&
          !this.$store.state.statistics.date_end
      ) {
        let end = this.$moment().format("YYYY-MM-DD");
        let start = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
        this.$store.state.statistics.date_start =
            this.$moment(start).format("DD.MM.YYYY");
        this.$store.state.statistics.date_end =
            this.$moment(end).format("DD.MM.YYYY");
      }
      this.hotelName = this.objects.list.find((x) => x.id === id).name;
      this.getBannersStatistics();
    },
    changeDates(dates) {
      this.labels = [];
      if (dates.date_start && dates.date_end) {
        this.$store.state.statistics.date_start = this.$moment
            .unix(dates.date_start)
            .format("DD.MM.YYYY");
        this.$store.state.statistics.date_end = this.$moment
            .unix(dates.date_end)
            .format("DD.MM.YYYY");
      } else {
        this.$store.state.statistics.date_start = dates.date_start;
        this.$store.state.statistics.date_end = dates.date_end;
      }
      this.getBannersStatistics();
    },
    async getBannersStatistics(){
      this.introChart = {
        All:[]
      };
      this.modalChart = {
        show:{
          All:[]
        },
        click_link:{
          All:[]
        },
        click_video_link:{
          All:[]
        }
      };
      this.bottomChart = {
        show:{
          All:[]
        },
        click_link:{
          All:[]
        },
        click_video_link:{
          All:[]
        }
      };
      this.dateArr = [];
      const payload = {
        hotel_id: this.$store.state.statistics.object_id,
        date_start: this.formatDateForStatistics(
            this.$store.state.statistics.date_start
        ),
        date_end: this.formatDateForStatistics(
            this.$store.state.statistics.date_end
        ),
      };
      const introStatistics = await StatisticsService.getIntroStatistics(payload);
      const modalStatistics = await StatisticsService.getModalBannerStatistics(payload);
      const bottomStatistics = await StatisticsService.getBottomBannerStatistics(payload);
      for(let key in introStatistics){
        this.clearDates.push(key);
        this.dateArr.push(this.formatDateForChart(key));
        if(introStatistics[key]){
          for(let banner_id in introStatistics[key]){
            const banner = introStatistics[key][banner_id];
            if(typeof banner !== 'number'){
              const candidate = this.introBanners.find(x => x.banner_id === banner.banner_id);
              if(!candidate){
                this.introBanners.push(banner);
              }
              this.introChart[banner.banner_id] = []
            }
          }
        }
      }
      for(let key in modalStatistics){
        if(modalStatistics[key]){
          for(let i = 0;i<modalStatistics[key].length;i++){
            const banner = modalStatistics[key][i];
            const candidate = this.modalBanners.find(x => x.banner_id === banner.banner_id);
            if(!candidate){
              this.modalBanners.push(banner);
            }
            if(banner.show){
              this.modalChart.show[banner.banner_id] = [];
            }
            if(banner.click_video_link){
              this.modalChart.click_video_link[banner.banner_id] = []
            }
            if(banner.click_link){
              this.modalChart.click_link[banner.banner_id] = [];
            }
          }
        }
      }
      for(let key in bottomStatistics){
        if(bottomStatistics[key]){
          for(let i = 0;i<bottomStatistics[key].length;i++){
            const banner = bottomStatistics[key][i];
            const candidate = this.bottomBanners.find(x => x.banner_id === banner.banner_id);
            if(!candidate){
              this.bottomBanners.push(banner);
            }
            if(banner.show){
              this.bottomChart.show[banner.banner_id] = [];
            }
            if(banner.click_video_link){
              this.bottomChart.click_video_link[banner.banner_id] = []
            }
            if(banner.click_link){
              this.bottomChart.click_link[banner.banner_id] = [];
            }
          }
        }
      }
      for(let key in introStatistics){
        if(introStatistics[key]){
          for(let type in this.introChart){
            this.introChart[type].push(0);
          }
          for(let banner_id in introStatistics[key]) {
            const banner = introStatistics[key][banner_id];
            if(typeof banner === 'number'){
             this.introChart.All[this.introChart.All.length-1] = banner;
            }else{
              this.introChart[banner.banner_id][this.introChart[banner.banner_id].length-1] = banner.show;
            }
          }
        }else{
          for(let type in this.introChart){
            this.introChart[type].push(0);
          }
        }
      }
      for(let key in modalStatistics){
        if(modalStatistics[key]){
          for(let event in this.modalChart){
            for(let type in this.modalChart[event]){
              this.modalChart[event][type].push(0);
            }
          }
          for(let i = 0;i<modalStatistics[key].length;i++){
            const banner = modalStatistics[key][i];
            if(banner.show){
              this.modalChart.show[banner.banner_id][this.modalChart.show[banner.banner_id].length-1] = +banner.show;
              this.modalChart.show.All[this.modalChart.show.All.length-1] += +banner.show
            }
            if(banner.click_video_link){
              this.modalChart.click_video_link[banner.banner_id][this.modalChart.show[banner.banner_id].length-1] = +banner.click_video_link;
              this.modalChart.click_video_link.All[this.modalChart.show.All.length-1] += +banner.click_video_link
            }
            if(banner.click_link){
              this.modalChart.click_link[banner.banner_id][this.modalChart.show[banner.banner_id].length-1] = +banner.click_link;
              this.modalChart.click_link.All[this.modalChart.show.All.length-1] += +banner.click_link
            }
          }
        }else{
          for(let event in this.modalChart){
            for(let type in this.modalChart[event]){
              this.modalChart[event][type].push(0);
            }
          }
        }
      }
      for(let key in bottomStatistics){
        if(bottomStatistics[key]){
          for(let event in this.bottomChart){
            for(let type in this.bottomChart[event]){
              this.bottomChart[event][type].push(0);
            }
          }
          for(let i = 0;i<bottomStatistics[key].length;i++){
            const banner = bottomStatistics[key][i];
            if(banner.show){
              this.bottomChart.show[banner.banner_id][this.bottomChart.show[banner.banner_id].length-1] = +banner.show;
              this.bottomChart.show.All[this.bottomChart.show.All.length-1] += +banner.show
            }
            if(banner.click_video_link){
              this.bottomChart.click_video_link[banner.banner_id][this.bottomChart.show[banner.banner_id].length-1] = +banner.click_video_link;
              this.bottomChart.click_video_link.All[this.bottomChart.show.All.length-1] += +banner.click_video_link
            }
            if(banner.click_link){
              this.bottomChart.click_link[banner.banner_id][this.bottomChart.show[banner.banner_id].length-1] = +banner.click_link;
              this.bottomChart.click_link.All[this.bottomChart.show.All.length-1] += +banner.click_link
            }
          }
        }else{
          for(let event in this.bottomChart){
            for(let type in this.bottomChart[event]){
              this.bottomChart[event][type].push(0);
            }
          }
        }
      }

    }
  },
  computed:{
    ...mapState(["objects"]),
    totalModal(){
      return this.modalChart[this.eventStatuses[this.current_event_modal]][this.current_banner_modal].reduce((acc,item) => {acc+=item;return acc;},0)
    },
    totalBottom(){
      return this.bottomChart[this.eventStatuses[this.current_event_bottom]][this.current_banner_bottom].reduce((acc,item) => {acc+=item;return acc;},0)
    },
    introChartData(){
      return this.introChart[this.current_banner_intro]?this.introChart[this.current_banner_intro]:[];
    },
    totalIntro(){
      return this.introChartData.reduce((acc,item) => {acc+=item;return acc;},0)
    },
    introTableItems(){
      const items = []
      for(let i = 0;i<this.clearDates.length;i++){
        const payload = {
          date:"",
          value:0
        }
        payload.date = this.formatDateForTable(this.clearDates[i]);
        payload.value = this.introChart[this.current_banner_intro][i]?this.introChart[this.current_banner_intro][i]:0;
        items.push(payload);
      }
      return items;
    },
    modalTableItems(){
      const items = []
      for(let i = 0;i<this.clearDates.length;i++){
        const payload = {
          date:"",
          value:0
        }
        payload.date = this.formatDateForTable(this.clearDates[i]);
        payload.value = this.modalChart[this.eventStatuses[this.current_event_modal]][this.current_banner_modal][i]?this.modalChart[this.eventStatuses[this.current_event_modal]][this.current_banner_modal][i]:0;
        items.push(payload);
      }
      return items;
    },
    bottomTableItems(){
      const items = []
      for(let i = 0;i<this.clearDates.length;i++){
        const payload = {
          date:"",
          value:0
        }
        payload.date = this.formatDateForTable(this.clearDates[i]);
        payload.value = this.bottomChart[this.eventStatuses[this.current_event_bottom]][this.current_banner_bottom][i]?this.bottomChart[this.eventStatuses[this.current_event_bottom]][this.current_banner_bottom][i]:0;
        items.push(payload);
      }
      return items;
    }
  },
  mixins:[titleMixin],
  title(){
    return 'Banner statistics'
  },
}
</script>

<style scoped lang="scss">
.statistics {
  @include box;
  display: flex;
  // align-items: flex-end;
  margin-bottom: 30px;

  canvas {
    width: 100% !important;
    height: 300px !important;
  }
}

.statistics__statistics {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(66.66% + 20px);
}

.statistics__type {
  position: absolute;
  width: 320px;
  top: 30px;
  left: 60px;
}

.statistics__chart {
  margin-top: auto;
}

.statistics__info {
  display: flex;
  flex-direction: column;
  width: calc(33.33% - 20px);
}

.statictics__caption {
  font-size: 16px;
  font-weight: 600;
  padding: 40px 40px 0;
}

.statistics__period {
  margin-bottom: auto;
  padding: 40px;
  font-weight: 500;
  font-size: 14px;
  color: #7f8385;
  span {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 80px;
    line-height: 1.21;
    color: #007db5;
    white-space: nowrap;
  }
}

.statistics__total {
  margin-top: auto;
  padding: 40px;
  border-radius: 0 0 5px 0;
  background: rgb(190, 193, 194);
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  span {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.22;
  }
}

.statistics__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.statistics canvas{
  height: 415px !important;
}
</style>
